
import '../common/js/plugins.js';
import { i18n } from '../common/js/i18n';
import SearchBar from '@otp-dev/branch-atm-shared/src/components/SearchBar';
export default {
	data: () => ({
		model: {
			branchOrATM: null,
			lat: null,
			lng: null,
			geolocate: false
		},
		loadType: 'init'
	}),
	props: {
		loadFonts: {
			required: false,
			default: true
		},
		searchPageUrl: {
			requied: true
		},
		searchType: {
			required: false
		},
		gmapsApiKey: {
			type: String,
			required: true
		}
	},
	i18n,
	name: 'BranchAtmWidget',
	components: {
		SearchBar
	},
	computed: {
		hasBranchSelector() {
			return !this.searchType;
		}
	},
	methods: {
		loadGoogleMaps(type) {
			this.loadType = type;
			if (!window?.google?.maps) {
				const googleMapScript = document.createElement('SCRIPT');
				const url = `https://maps.googleapis.com/maps/api/js?key=${this.gmapsApiKey}&libraries=places&callback=vueGoogleMapsInit`;
				googleMapScript.setAttribute('src', url);
				googleMapScript.setAttribute('async', '');
				googleMapScript.setAttribute('defer', '');
				document.head.appendChild(googleMapScript);
			}
		},
		openBranchAtmLocator(params) {
			const searchParams = new URLSearchParams({
				branchOrATM: params.branchOrATM,
				northEastLat: params.northEast.lat ? params.northEast.lat : null,
				northEastLng: params.northEast.lng ? params.northEast.lng : null,
				southWestLat: params.southWest.lat ? params.southWest.lat : null,
				southWestLng: params.southWest.lng ? params.southWest.lng : null,
				address: params.address ? params.address : null,
				geolocate: params.geolocate
			});
			window.location.href = `${this.searchPageUrl}?${searchParams}`;
		}
	},
	mounted() {
		if (this.loadFonts) {
			require('@otp-dev/branch-atm-shared/src/css/fonts/fonts.css');
		}
	},
	created() {
		window.vueGoogleMapsInit = () => {
			window.dispatchEvent(new CustomEvent('googleMapsLoaded'));
			if (this.loadType == 'geolocate') {
				this.$refs.searchBar.$refs.locationInput.geolocate();
			}
		};
		this.model.branchOrATM = this.searchType || 'fiok';
	}
};
